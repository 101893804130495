<template>
  <!--====================  testimonial slider area ====================-->
  <div class="testimonial-slider-area testimonial-slider-area-bg">
    <div class="container-fluid">
      <div class="testimonial-slider">
        <div class="testimonial-slider__container-area">
          <div class="swiper-container testimonial-slider__container">
            <div class="swiper-wrapper testimonial-slider__wrapper">
              <swiper :options="swiperOption">
                <div v-for="(praise,index) in $d.praiseList" :key="index" :style="{ backgroundImage: `url(${praise.bgImgUrl})` }" class="swiper-slide testimonial-slider__single-slide">
                  <div class="container section-space--inner--100">
                    <div class="content">
                      {{ praise.text }}
                    </div>
                    <div class="author">
                      ──── {{ praise.author }}
                    </div>
                  </div>
                </div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of testimonial slider area  ====================-->
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        watchSlidesVisibility: true,
        effect: 'fade',
        spaceBetween: 30,
        autoplay: {
          delay: 3000
        }
        // ,
        // pagination: {
        //   el: '.swiper-pagination-3',
        //   type: 'bullets',
        //   clickable: true
        // }
      }
    }
  }
}
</script>
