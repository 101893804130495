<template>
  <div class="hero-slider-area">
    <div class="hero-slider__container-area">
      <div class="swiper-container hero-slider__container">
        <div class="swiper-wrapper hero-slider__wrapper">
          <swiper :options="swiperOption">
            <!--=======  single slider item  =======-->
            <div v-for="(item,index) in sliderData" :key="index" class="swiper-slide hero-slider__single-item" :style="{ backgroundImage: `url(${item.bgImgUrl})` }">
              <div class="hero-slider__content-wrapper">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="hero-slider__content" :class="[item.addClass]">
                        <h2 class="hero-slider__title">{{ item.title }}</h2>
                        <p class="hero-slider__text">{{ item.desc }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--=======  End of single slider item  =======-->
            <!-- <div class="ht-swiper-button-prev ht-swiper-button-prev-13 ht-swiper-button-nav d-none d-xl-block"><i class="ion-ios-arrow-left" /></div>
            <div class="ht-swiper-button-next ht-swiper-button-next-13 ht-swiper-button-nav d-none d-xl-block"><i class="ion-ios-arrow-forward" /></div> -->
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    sliderData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOption: {
        speed: 300,
        loop: true,
        effect: 'fade',
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.ht-swiper-button-next',
          prevEl: '.ht-swiper-button-prev'
        }
      }
    }
  }
}
</script>
