<template>
  <div class="project-gallery-area section-space--inner--50">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- section title -->
          <div class="section-title-area text-center section-space--bottom--50">
            <h2 class="section-title">新加坡案例赏析</h2>
            <p class="section-subtitle">Appreciation of Singapore Business cases</p>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="project-gallery-wrapper">
            <div class="row">
              <div v-for="(item,index) in $d.projectList" :key="index" :class="'col-md-' + wid[index]">
                <div class="single-gallery-project">
                  <div class="single-gallery-project__image">
                    <img :src="item.imgUrl" class="img" alt="">
                  </div>
                  <div class="single-gallery-project__content">
                    <h4 class="title">{{ item.title }}</h4>
                    <router-link to="'/project-details?id='+index">Read More</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wid: [7, 5, 5, 7]
    }
  }
}
</script>

<style lang="scss">

</style>
