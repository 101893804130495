<template>
  <div class="feature-icon-area section-space--inner--50 grey-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="feature-icon-wrapper">
            <div class="row">
              <div v-for="(feature,index) in $d.featureList" :key="index" class="col-lg-2 col-6">
                <div class="single-feature-icon">
                  <div class="single-feature-icon__image">
                    <img :src="feature.icon" class="img-fluid" alt="icon">
                  </div>
                  <h3 class="single-feature-icon__title">{{ feature.title }}</h3>
                  <p class="single-feature-icon__content">{{ feature.desc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
