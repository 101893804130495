<template>
  <!--====================  video cta area ====================-->
  <div class="video-cta-area section-space--inner--50">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="video-cta-content">
            <!-- <h4 class="video-cta-content__small-title">{{ data.sectionSubTitle }}</h4> -->
            <h3 class="video-cta-content__title">{{ $d.name }}</h3>
            <p class="video-cta-content__text">{{ $d.desc }}</p>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-md-12">
          <div class="cta-video-image">
            <div class="video-popup">
              <silent-box :image="{src:$d.videoUrl,autoplay:true}" :autoplay="true">
                <template #silentbox-item>
                  <div class="cta-video-image__image">
                    <img :src="$d.imgUrl" class="img-fluid" alt="image">
                  </div>
                  <div class="cta-video-image__icon">
                    <i class="ion-ios-play" />
                  </div>
                </template>
              </silent-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of video cta area  ====================-->
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
