<template>
  <div class="main-container">

    <Header />

    <Hero :slider-data="$d.sliderList" />

    <AboutSection />

    <Feature />

    <ServiceTabs />

    <Praise />

    <ProjectGallery />

    <BrandCarousel add-class="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up" />
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from '@/components/Header'
import Hero from '@/components/sections/Hero'
import ServiceTabs from '@/components/sections/ServiceTabs'
import AboutSection from '@/components/sections/AboutSection'
import ProjectGallery from '@/components/sections/ProjectGallery'
import Praise from '@/components/Praise'
import BrandCarousel from '@/components/BrandCarousel'
import Footer from '@/components/Footer'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
import Feature from '@/components/Feature'

export default {
  components: {
    Header,
    Hero,
    ServiceTabs,
    Feature,
    AboutSection,
    ProjectGallery,
    Praise,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
    }
  },
  metaInfo: {
    title: '乌节国际',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>
