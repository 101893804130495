<template>
  <!--====================  brand logo area ====================-->
  <div class="brand-logo-slider-area section-space--inner--50" :class="addClass">
    <div class="section-title section-space--bottom--20">
      战略合作单位
    </div>
    <div class="container">
      <!-- brand logo slider -->
      <div class="brand-logo-slider__container-area">
        <div class="swiper-container brand-logo-slider__container">
          <div class="swiper-wrapper brand-logo-slider__wrapper">
            <swiper :options="swiperOption">
              <div v-for="(item,index) in $d.friendLogoUrlList" :key="index" class="swiper-slide brand-logo-slider__single">
                <div class="image text-center">
                  <a>
                    <img :src="item" class="img-fluid" alt="brand logo">
                  </a>
                </div>
              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of brand logo area  ====================-->
</template>

<script>
export default {
  props: ['addClass'],
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1000,
        watchSlidesVisibility: true,
        spaceBetween: 30,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        // Responsive breakpoints
        breakpoints: {
          1499: {
            slidesPerView: 5,
            centeredSlides: true
          },
          768: {
            slidesPerView: 4,
            centeredSlides: true
          },
          480: {
            slidesPerView: 3,
            centeredSlides: true
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-logo-slider-area{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-logo-slider__single{
  display: flex;
  justify-content: center;
  align-items: center;
}
.image{
  display: flex;
}
</style>
