<template>
  <!--====================  service tab area ====================-->
  <div class="service-tab-area section-space--inner--50">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-area text-center">
            <h2 class="section-title section-space--bottom--50">业务范围</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <!-- service tab wrapper -->
          <div class="service-tab-wrapper">
            <div class="row no-gutters">
              <div class="col-md-4 ">
                <div class="nav nav-tabs service-tab__link-wrapper">
                  <a v-for="(service,index) in services" :key="index" class="nav-item nav-link col-md-12 col-5" :class="{ active: isActive(index) }" :href="service.title" @click.prevent="setActive(index)">
                    <span class="icon">
                      <i :class="service.icon" />
                    </span>
                    <span class="text">{{ service.title }}</span>
                  </a>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content service-tab__content-wrapper">
                  <div v-for="(service,index) in services" :id="index" :key="activeItem+index" class="tab-pane fade" :class="{ 'active show': isActive(index) }">
                    <div class="service-tab__single-content-wrapper bg-img" :style="{'background-image':'url('+service.imgUrl+')'}">
                      <div class="service-tab__single-content">
                        <h3 class="service-tab__title">{{ service.title }}</h3>
                        <p class="service-tab__text">{{ service.desc }}</p>
                        <router-link :to="'/service-details?id='+index" class="see-more-link">更多</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of service tab area  ====================-->
</template>

<script>

export default {
  data() {
    return {
      activeItem: 0,
      services: this.$d.serviceList
    }
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    }
  }
}
</script>
